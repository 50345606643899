
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import RegisterForm from "@/modules/register/components/RegisterForm.vue";
import LoginForm from "@/modules/login/components/LoginForm.vue";
import LinkBtn from "@/components/common/LinkBtn.vue";
import { isNil } from "lodash";
import BSection from "@/components/bs/BSection.vue";
import EBtn from "@/components/common/EBtn.vue";

type AuthComponent = "register-form" | "login-form";

@Component({
  components: { EBtn, BSection, LinkBtn, RegisterForm, LoginForm },
})
export default class LoginRegister extends Vue {
  @VModel({ type: Boolean, default: null }) bLogin!: boolean | null;
  @Prop(Boolean) readonly preventRedirect!: boolean;

  get sComponent(): AuthComponent | null {
    if (isNil(this.bLogin)) {
      return null;
    }

    return this.bLogin ? "login-form" : "register-form";
  }

  get sLabel(): "have.not.account" | "have.account" {
    return this.bLogin ? "have.not.account" : "have.account";
  }

  onShowForm(bValue: boolean) {
    this.$emit("input", bValue);
  }
}
